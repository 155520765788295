export interface ChatInterface {
    messages: string[],
    contactName: string,
    contactEmoji: string,
    isCheckbox?: boolean,
    isChatWithIntegration?: boolean,
    time: number,
    answers: Answer[],
}

export interface Answer {
    text: string,
    reactions: string[],
    price: number,
    color: string,
    portrait?: {
        thrifty?: number,
        schoolStar?: number,
        generous?: number,
        manOfWords?: number,
    },
    moral?: Moral,
    options?: string[],
    isPositive?: boolean,
}

export interface Moral {
    text: string,
    isPositive: boolean,
}

export const chatsArray: ChatInterface[] = [
    {
        contactName: 'Маленькие сократики',
        contactEmoji: '🤔',
        messages: ['Ваш ребёнок записался в кружок юных философов', 'Сдайте деньги'],
        isCheckbox: false,
        time: 12,
        answers: [
            {
                text: 'Дать денег',
                reactions: ['Ну хоть кто-то в нашей семье будет знать, в чём смысл жизни'],
                moral: { 
                    text: 'Хоть вам и кажется, что философствовать можно бесплатно, школа имеет право собирать деньги на дополнительные занятия.',
                    isPositive: true 
                },
                price: 2000,
                color: '#63b22e',
                portrait: {generous: 1}
            },
            {
                text: 'Не давать',
                reactions: ['Философствовать можно бесплатно', 'У нас в подъезде все стены философскими надписями исписаны'],
                price: 0,
                color: '#e50050'
            },
        ]
    },
    {
        contactName: 'Наш 5Б класс',
        contactEmoji: '✨',
        messages: ['Дорогие родители, есть предложение всем скинуться на букет ко дню учителя.', 'Кто деньги соберёт?'],
        isCheckbox: false,
        time: 12,
        answers: [
            {
                text: 'Дать денег',
                reactions: ['Я соберу!', 'Кстати, вы теперь мне должны'],
                moral: { 
                    text: 'Хорошо, что вы решили скинуться на общий букет, а не покупать ребёнку отдельный. И денег сэкономили, и планете помогли.',
                    isPositive: true, 
                },
                price: 2000,
                color: '#63b22e',
                portrait: {schoolStar: 1},
            },
            {
                text: 'Не давать',
                reactions: ['А у нас в огороде свои цветы растут', 'Ромашки там, крапива...'],
                price: 0,
                color: '#e50050'
            },
        ]
    },
    {
        contactName: 'Сын',
        contactEmoji: '👶',
        messages: ['Маааам', 'Помнишь ты мне обещала наушники купить?', 'Если четверть без троек закончу', 'Только, плиииз, не смотри дневник'],
        isCheckbox: false,
        time: 12,
        answers: [
            {
                text: 'Дать денег',
                reactions: ['Это бабушка обещала', 'Она мне деньги переведёт — куплю'],
                moral: { 
                    text: 'Здорово всей семьёй порадовать ребёнка в конце четверти, пусть только бабушки и дедушки не бегают по городу с наличкой, а переводят деньги. Кстати, наушники и на дистанционке пригодятся!',
                    isPositive: true
                },
                price: 2000,
                color: '#63b22e',
                portrait: {generous: 0.5}
            },
            {
                text: 'Не давать',
                reactions: ['Слууушай', 'Я только что заглянула в твой дневник...'],
                price: 0,
                color: '#e50050'
            },
        ]
    },
    {
        contactName: 'Дима, папа Славы',
        contactEmoji: '🧔',
        messages: ['А ваш сын у моего деньги занимал', 'На чипсы', 'Верните, а!'],
        isCheckbox: false,
        time: 12,
        answers: [
            {
                text: 'Дать денег',
                reactions: ['Судя по сумме, он эти чипсы в ресторане заказывал'],
                moral: {
                    text: 'Чипсы, конечно, лучше покупать пореже, но долги придётся возвращать.',
                    isPositive: true
                },
                price: 2000,
                color: '#63b22e',
                portrait: {generous: 1}
            },
            {
                text: 'Не давать',
                reactions: ['Не верю!', 'Он ест только здоровую пищу', 'Салат, сельдерей...'],
                price: 0,
                color: '#e50050'
            },
        ]
    },
    {
        contactName: 'Наш 5Б класс',
        contactEmoji: '✨',
        messages: ['В ноябре у Анечки, Костика и Дашеньки день рождения',
        'Давайте скинемся и подарим им брелоки Gucci: деткам такое понравится!'],
        isCheckbox: false,
        time: 12,
        answers: [
            {
                text: 'Дать денег',
                reactions: ['Перевёл', 'Чур на наш день рождения собираем на айфон!'],
                price: 2000,
                color: '#63b22e',
                portrait: {schoolStar: 1},
            },
            {
                text: 'Не давать',
                reactions: ['Какие ещё гуччи?', 'Книга — лучший подарок'],
                price: 0,
                color: '#e50050'
            },
        ]
    },
    {
        contactName: 'Сын',
        contactEmoji: '👶',
        messages: ['Мааам', 'Нам на уроке фильм показали про собак в приюте', 'Ты, кстати, много зарабатываешь?', 'Может, мы этот приют купим? 😢'],
        isCheckbox: false,
        time: 12,
        answers: [
            {
                text: 'Дать денег',
                reactions: ['Давай лучше деньги в приют переведём'],
                price: 2000,
                color: '#63b22e',
                portrait: {generous: 0.5}
            },
            {
                text: 'Не давать',
                reactions: ['У меня на собак аллергия!', 'А тебя укусила одна', 'Чтоб я больше о них не слышала'],
                price: 0,
                color: '#e50050'
            },
        ]
    },
    {
        contactName: 'Тамара Петровна',
        contactEmoji: '👩‍🏫',
        messages: ['Доброго времени суток!', 'Собираем на школьную форму'],
        isCheckbox: false,
        time: 12,
        answers: [
            {
                text: 'Дать денег',
                reactions: ['Удобно!', 'На одежде сэкономим'],
                moral: { 
                    text: 'Школа может вводить форму, а вот требовать купить её у своего поставщика — нет.',
                    isPositive: false 
                },
                price: 2000,
                color: '#63b22e',
                portrait: {manOfWords: 1},
            },
            {
                text: 'Не давать',
                reactions: ['Да в ней же жарко и зимой, и летом', 'И некрасиво', 'В тиктоке засмеют'],
                price: 0,
                color: '#e50050'
            },
        ]
    },
    {
        contactName: 'Наш 5Б класс',
        contactEmoji: '✨',
        messages: ['Такая погода хорошая!', 'Может экскурсию детям организуем?', 'По золотому кольцу'],
        isCheckbox: false,
        time: 12,
        answers: [
            {
                text: 'Дать денег',
                reactions: ['Вот здорово!', 'И в Великий Устюг ещё', 'К Деду Морозу'],
                moral: { 
                    text: 'Время сейчас небезопасное — лучше дома посидеть. Или пройти онлайн-экскурсию — кстати, это бесплатно.',
                    isPositive: false 
                },
                price: 2000,
                color: '#63b22e',
                portrait: {schoolStar: 1},
            },
            {
                text: 'Не давать',
                reactions: ['Вы новости читаете?', 'Коронавирус бушует, а вы — экскурсии'],
                price: 0,
                color: '#e50050'
            },
        ]
    },
    {
        contactName: 'Неизвестный номер',
        contactEmoji: '👽',
        messages: ['Мама, я попал в беду! Срочно переведи деньги по этому номеру'],
        isCheckbox: false,
        isChatWithIntegration: true,
        time: 12,
        answers: [
            {
                text: 'Дать денег',
                reactions: ['СБП сводит к минимуму риск перевести деньги мошенникам – сервис следит за безопасностью пользователя. Так что совершить такой перевод не вышло!'],
                price: 2000,
                color: '#63b22e',
                isPositive: true,
            },
            {
                text: 'Не давать',
                reactions: ['СБП сводит к минимуму риск перевести деньги мошенникам – сервис следит за безопасностью пользователя. Так что совершить такой перевод не вышло!'],
                price: 0,
                color: '#e50050',
                isPositive: false,
            },
        ]
    },
    {
        contactName: 'Тамара Петровна',
        contactEmoji: '👩‍🏫',
        messages: ['Собираем на рабочие тетради!',
        'По химии, физике, литературе, по родной русской литературе...'],
        isCheckbox: false,
        time: 12,
        answers: [
            {
                text: 'Дать денег',
                reactions: ['Всё правильно', 'Мы рабочий класс, и у наших детей будут рабочие тетради'],
                moral: { 
                    text: 'По закону все учебные материалы по школьной программе ученику выдаёт школа.',
                    isPositive: false 
                },
                price: 2000,
                color: '#63b22e',
                portrait: {manOfWords: 1},
            },
            {
                text: 'Не давать',
                reactions: ['Он ещё прошлые не исписал!', 'За первый класс'],
                price: 0,
                color: '#e50050'
            },
        ]
    },
    {
        contactName: 'Наш 5Б класс',
        contactEmoji: '✨',
        messages: ['Помните фильм про собачий приют?',
        'Детки так впечатлились, что хотят заказать живой уголок', 'Скинемся?'],
        isCheckbox: false,
        time: 12,
        answers: [
            {
                text: 'Дать денег',
                reactions: ['Отличная идея!', 'А может, и контактный зоопарк организуем?'],
                moral: { 
                    text: 'Живые уголки запрещены нормами СанПиН, а если животные и выживут после игр со школьниками, то куда отправятся, если кто-то напишет жалобу? Ну не к вам же домой?',
                    isPositive: false 
                },
                price: 2000,
                color: '#63b22e',
                portrait: {schoolStar: 1},
            },
            {
                text: 'Не давать',
                reactions: ['Я против', 'Животные не выживут после наших детей'],
                price: 0,
                color: '#e50050'
            },
        ]
    },
    {
        contactName: 'Тамара Петровна',
        contactEmoji: '👩‍🏫',
        messages: ['Отправляю вам программу по ИЗО на следующую четверть',
        'Строим шалаш, лепим вазу, вышиваем гобелен', 'Закупите, пожалуйста, материалы'],
        isCheckbox: true,
        time: 12,
        answers: [
            {
                text: 'Купить',
                reactions: ['Художникам и так непросто, пусть хоть материалы хорошие будут'],
                price: 2000,
                color: '#63b22e',
                portrait: {manOfWords: 1},
                options: ['краски акварельные', 'цветные карандаши', 'мольберт для пленэра',
                'голова Давида', 'чучела птиц'],
            },
            {
                text: 'Ничего не покупать',
                reactions: ['Всё нужное и так под ногами валяется',
                'Палки, шишки, коробки'],
                price: 0,
                color: '#e50050'
            },
        ]
    },
]

export interface momGameMessageInterface {
    type: 'gif' | 'text',
    src?: string,
    text?: string,
}

export interface momGameAnswerInterface {
    text: string,
    isEmptyMessage?: boolean,
    isMessage?: boolean,
    reaction?: momGameMessageInterface,
}

export interface MomGameChatInterface {
    message: momGameMessageInterface,
    answers: momGameAnswerInterface[],
}

export const momGameArray: MomGameChatInterface[] = [
    {
        message: {
            type: 'gif',
            src: 'https://3d-galleru.ru/cards/0/47/phvnhdmam3ur2x5/prosto-tak-dlya-nastroeniya.gif'
        },
        answers: [
            {
                text: '👍',
                reaction: {
                    type: 'gif',
                    src: 'https://www.pozj.ru/images/img/69ef1a553ae3d80c94c7f8c76be2d1aa.jpg'
                },
                isMessage: true,
            },
            {
                text: '❤️',
                reaction: {
                    type: 'gif',
                    src: 'https://i.pinimg.com/736x/00/bb/08/00bb08c6be37ee49646fb9b4a122bb90--clip-google.jpg'
                },
                isMessage: true,
            },
            {
                text: 'Не отвечать',
                isEmptyMessage: true,
                reaction: {
                    type: 'gif',
                    src: 'https://bestgif.su/_ph/42/2/344411485.gif'
                }
            }
        ]
    },
    {
        message: {
            type: 'text',
            text: 'Не забудь, что у тёти Лены из Самары завтра день рождения'
        },
        answers: [
            {
                text: '👍',
                isMessage: true,
            },
            {
                text: '👍👍👍',
                isMessage: true,
            },
            {
                text: 'Кто это?',
                isMessage: true,
                reaction: {
                    type: 'gif',
                    src: 'https://media4.giphy.com/media/MWt0P8kY1gyfKIlDFg/giphy.gif'
                }
            }
        ]
    },
    {
        message: {
            type: 'text',
            text: 'Как думаешь, брать билеты в Турцию?'
        },
        answers: [
            {
                text: 'Да',
                isMessage: true,
            },
            {
                text: 'Нет',
                isMessage: true,
            },
            {
                text: 'Проигнорировать',
                isEmptyMessage: true,
            }
        ]

    }
]
