import React from 'react';
import { momGameAnswerInterface, MomGameChatInterface, momGameMessageInterface } from './chatsArray'

interface State {
    messages: (momGameMessageInterface | string)[],
}

interface Props {
    currentChat: MomGameChatInterface,
    onNext: () => void,
}

interface State {
    isShowQuestionTypingDot: boolean,
    isShowAnswerTypingDot: boolean,
    isShowAnwersButtons: boolean,
    isShowNextButton: boolean,
}

export default class MomGame extends React.Component<Props, State> {
    private sleep = (time: any) => new Promise(r => setTimeout(r, time))
    private messageWrapper: any
    private messageSound = new Audio('https://sbp-game.mel.fm/images/message.mp3');

    constructor(props: Props) {
        super(props);
        this.state = {
            messages: [this.props.currentChat.message],
            isShowQuestionTypingDot: false,
            isShowAnswerTypingDot: false,
            isShowAnwersButtons: true,
            isShowNextButton: false,
        }
    }

    render() {
        return (
            <div className="momgame-layout">
                <div className="momgame-aligner"></div>
                <div className="momgame chat">
                    <div className="chat__contact">
                        <div className="chat__contact-icon momgame-icon">
                            <div>❤️</div>
                        </div>
                        <div>Мамуля</div>
                    </div>
                    <div className="momgame__messages-wrapper chat__messages-wrapper">
                        {this.state.messages.map((message) => {
                            if (typeof (message) === 'string') {
                                return (
                                    <div className="chat__message chat__message_align-right" key={message}>
                                        {message}
                                    </div>
                                )
                            } else if (message.type === 'gif') {
                                return (
                                    <div className="chat__message momgame__message-gif" key={message.src}>
                                        <img className="momgame__image" src={message.src} alt="gif" />
                                    </div>
                                )
                            } else {
                                return (
                                    <div className="chat__message" key={message.text}>
                                        {message.text}
                                    </div>
                                )
                            }
                        })}
                        <div className="chat__questionTypingDot-wrapper">
                            <span
                                className={"chat__typingDot"}
                                hidden={!this.state.isShowQuestionTypingDot}
                            />
                        </div>
                        <div className="chat__answerTypingDot-wrapper" >
                            <span
                                className={"chat__typingDot"}
                                hidden={!this.state.isShowAnswerTypingDot}
                            />
                        </div>
                    </div>
                    <div className="chat__answers-buttons-aligner">
                        {this.state.isShowAnwersButtons && (
                            <div className="chat__answers-buttons-wrapper momgame__answers-buttons-aligner">
                                {this.props.currentChat.answers.map(answer =>
                                    <button
                                        key={answer.text}
                                        className={
                                            `momgame__answer-button ${answer.isMessage ? 'triangle' : ''}`
                                        }
                                        onClick={() => this.onAnswerButtonClicked(answer)}
                                    >
                                        {answer.text}
                                    </button>
                                )}
                            </div>
                        )}
                        {this.state.isShowNextButton && (
                            <button
                                className="chat__answers-next-button"
                                onClick={() => this.props.onNext()}
                            >
                                <div className="button-inner-elements">
                                    <div>Дальше</div>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95 99">
                                        <g fill="none" fillRule="evenodd">
                                            <g strokeWidth="6.744">
                                                <g>
                                                    <path stroke="#FFFFFF" d="M0 47.3L85.6 47.3" transform="translate(-158 -255) translate(158 258)" />
                                                    <path stroke="#FFFFFF" d="M42.9 0L89.5 46.6" transform="translate(-158 -255) translate(158 258)" />
                                                    <path stroke="#FFFFFF" d="M91.9 44.3L42.9 93.2" transform="translate(-158 -255) translate(158 258)" />
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </div>

                            </button>
                        )}
                        <div className="safari-after-fixer momgame" hidden={this.state.isShowAnwersButtons}></div>
                    </div>
                </div>
            </div>
        )
    }

    componentDidMount() {
        this.messageWrapper = window.document.getElementsByClassName('chat__messages-wrapper')[0]
    }

    private async onAnswerButtonClicked(answer: momGameAnswerInterface) {
        if (!answer.isEmptyMessage) {
            this.setState({
                isShowAnswerTypingDot: true,
                isShowAnwersButtons: false,
            })
            this.messageWrapper.scrollTop = this.messageWrapper.scrollHeight;
            await this.sleep(1200)
            this.setState(prevState => ({
                isShowAnswerTypingDot: false,
                messages: [...prevState.messages, answer.text]
            }))
            this.messageSound.play();
            this.messageWrapper.scrollTop = this.messageWrapper.scrollHeight;
        } else {
            this.setState({
                isShowAnwersButtons: false
            })
        }
        await this.sleep(400)

        if (answer.reaction) {
            this.setState({
                isShowQuestionTypingDot: true,
            })
            this.messageWrapper.scrollTop = this.messageWrapper.scrollHeight;
            await this.sleep(1200)

            if (answer.reaction.src) {
                let reaction: momGameMessageInterface = { type: 'gif', src: answer.reaction.src }
                this.setState(prevState => ({
                    isShowQuestionTypingDot: false,
                    isShowNextButton: true,
                    messages: [...prevState.messages, reaction],
                }))
                this.messageWrapper.scrollTop = this.messageWrapper.scrollHeight;
            } else if (answer.reaction.text) {
                let reaction: momGameMessageInterface = { type: 'text', text: answer.reaction.text }
                this.setState(prevState => ({
                    isShowQuestionTypingDot: false,
                    isShowNextButton: true,
                    messages: [...prevState.messages, reaction],
                }))
                this.messageWrapper.scrollTop = this.messageWrapper.scrollHeight;
            }
        } else {
            this.setState({
                isShowNextButton: true,
            })
        }
    }
}