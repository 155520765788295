import React from 'react';

import start_game from '../Icons/Balloons/start-game-balloon.png'
import balloon_one from '../Icons/Balloons/1.png'
import balloon_two from '../Icons/Balloons/2.png'
import balloon_three from '../Icons/Balloons/3.png'
import balloon_four from '../Icons/Balloons/4.png'
import balloon_five from '../Icons/Balloons/5.png'
import balloon_six from '../Icons/Balloons/6.png'
import balloon_seven from '../Icons/Balloons/7.png'
import balloon_eight from '../Icons/Balloons/8.png'
import balloon_nine from '../Icons/Balloons/9.png'
import balloon_ten from '../Icons/Balloons/10.png'
import startpage_red from '../Icons/Balloons/start-page-red.png'
import startpage_green from '../Icons/Balloons/start-page-green.png'
import game_end from '../Icons/Balloons/game-end.png'

interface Props {
    onNext: () => void,
}

interface State {
    isShowStartPage: boolean,
    isShowGame: boolean,
    isShowResult: boolean,
}

export class Balloons extends React.Component<Props, State> {
    private rootDom: any | undefined;
    private balloons: HTMLImageElement[] = []
    private sleep = (time: any) => new Promise(r => setTimeout(r, time))

    constructor(props: Props) {
        super(props)
        this.state = {
            isShowStartPage: true,
            isShowGame: false,
            isShowResult: false,
        }
    }

    render() {
        return (
            <div className="balloons__layout">
                {this.state.isShowResult && (
                    <div className="balloons__result-wrapper">
                        <div className="balloons__result-text">
                            Не вышло, ну и ладно — от ответственности так просто не уйдёшь! Возвращайтесь во взрослую жизнь. Кстати, вам сообщение.
                            <img
                                className='balloons__result-balloon1'
                                src={game_end}
                                alt='game-end' />
                            <img
                                className='balloons__result-balloon2'
                                src={game_end}
                                alt='game-end' />
                            <img
                                className='balloons__result-balloon3'
                                src={game_end}
                                alt='game-end' />
                        </div>
                        <button className="balloons__next-button" onClick={() => { this.props.onNext(); gtag('event', 'balloon-next-button-clicked') }}>
                            <div className="button-inner-elements">
                                <div>Дальше</div>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95 99">
                                    <g fill="none" fillRule="evenodd">
                                        <g strokeWidth="6.744">
                                            <g>
                                                <path stroke="#FFFFFF" d="M0 47.3L85.6 47.3" transform="translate(-158 -255) translate(158 258)" />
                                                <path stroke="#FFFFFF" d="M42.9 0L89.5 46.6" transform="translate(-158 -255) translate(158 258)" />
                                                <path stroke="#FFFFFF" d="M91.9 44.3L42.9 93.2" transform="translate(-158 -255) translate(158 258)" />
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        </button>
                    </div>
                )}
                {this.state.isShowStartPage &&
                    <div className="balloons__start-page">
                        <div className="balloons__title">
                            Сделайте паузу!
                            <img className="balloons__start-page__red-balloon"
                                src={startpage_red}
                                alt='red-balloon'
                            />
                            <img className="balloons__start-page__green-balloon"
                                src={startpage_green}
                                alt='green-balloon'
                            />
                        </div>
                        <div className="balloons__description">
                            Заплати, найди, купи — надоела ответственность! Представьте, что дела — это воздушные шарики, которые можно лопать. Бам! И нет никакой домашки. Бум! Продукты на неделю уже в холодильнике. Попробуйте сами!
                        </div>
                        <img
                            src={start_game}
                            onClick={() => { this.startGame(); gtag('event', 'balloon-game-start-button-clicked') }}
                            className="balloons__start-button"
                            alt="start-game"
                        >
                        </img>
                    </div>
                }
            </div>
        )
    }

    private async addBalloon() {
        let newBalloon = new Image();
        newBalloon.src = this.balloonsType[this.balloonCounter]
        this.balloonCounter += 1;
        newBalloon.className = 'balloons__balloon'
        newBalloon.style.top = `${110}%` //!! change start position
        newBalloon.onclick = (() => this.deleteElement(newBalloon))
        newBalloon.style.left = `${Math.random() * (70)}%`;
        this.rootDom.appendChild(newBalloon)
        this.balloons.push(newBalloon)
    }

    componentDidMount() {
        this.rootDom = window.document.getElementsByClassName('balloons__layout')[0]
    }

    private async startGame() {
        this.setState({
            isShowStartPage: false,
            isShowGame: true,
        })
        await this.addBalloon()
        let newInterval: any
        let newInterval2: any
        let newInterval3: any

        let interval = setInterval(() => this.addBalloon(), 1200)
        setTimeout(() => {
            clearInterval(interval);
            newInterval = setInterval(() => this.addBalloon(), 500)
            setTimeout(() => {
                clearInterval(newInterval);
                newInterval2 = setInterval(() => this.addBalloon(), 200);
                setTimeout(() => {
                    clearInterval(newInterval2)
                    newInterval3 = setInterval(() => this.addBalloon(), 100)
                    setTimeout(() => clearInterval(newInterval3), 4000)
                    setTimeout(() => { this.endGame(); }, 7000)
                }, 3000);
            }, 4000)
        }, 7000)
    }

    private endGame() {
        for (let i = 0; i < this.balloons.length; i++) {
            this.balloons[i].style.opacity = '0';
        }

        this.setState({
            isShowGame: false,
            isShowResult: true,
        })
    }

    private deleteElement(a: HTMLImageElement) {
        if (a.animate !== undefined) {
            a.animate([
                {
                    opacity: 1,
                    height: '276px',
                },
                {
                    opacity: 0,
                    height: '450px',
                }
            ], {duration: 150, fill: 'forwards'})
            setTimeout(() => a.remove(), 200)

            gtag('event', 'balloon-clicked');
        } else {
            a.remove()
        }
    }

    private balloonCounter = 0

    private balloonsType = [
        balloon_one, balloon_two, balloon_three, balloon_four,
        balloon_five, balloon_six, balloon_seven, balloon_eight, balloon_nine, balloon_ten,
        balloon_one, balloon_two, balloon_three, balloon_four,
        balloon_five, balloon_six, balloon_seven, balloon_eight, balloon_nine, balloon_ten,
        balloon_one, balloon_two, balloon_three, balloon_four,
        balloon_five, balloon_six, balloon_seven, balloon_eight, balloon_nine, balloon_ten,
        balloon_one, balloon_two, balloon_three, balloon_four,
        balloon_five, balloon_six, balloon_seven, balloon_eight, balloon_nine, balloon_ten,
        balloon_one, balloon_two, balloon_three, balloon_four,
        balloon_five, balloon_six, balloon_seven, balloon_eight, balloon_nine, balloon_ten,
        balloon_one, balloon_two, balloon_three, balloon_four,
        balloon_five, balloon_six, balloon_seven, balloon_eight, balloon_nine, balloon_ten,
        balloon_one, balloon_two, balloon_three, balloon_four,
        balloon_five, balloon_six, balloon_seven, balloon_eight, balloon_nine, balloon_ten,
        balloon_one, balloon_two, balloon_three, balloon_four,
        balloon_five, balloon_six, balloon_seven, balloon_eight, balloon_nine, balloon_ten,
        balloon_one, balloon_two, balloon_three, balloon_four,
        balloon_five, balloon_six, balloon_seven, balloon_eight, balloon_nine, balloon_ten,
        balloon_one, balloon_two, balloon_three, balloon_four,
        balloon_five, balloon_six, balloon_seven, balloon_eight, balloon_nine, balloon_ten,
        balloon_one, balloon_two, balloon_three, balloon_four,
        balloon_five, balloon_six, balloon_seven, balloon_eight, balloon_nine, balloon_ten,
    ]
}