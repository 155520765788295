import React from 'react';
import { interval } from 'd3-timer';

import StartPage from './StartPage';
import Chat from './Chat';
import Progress from './Progress';
import MoneyCounter from './MoneyCounter';
import Result from './Result';
import { chatsArray,  ChatInterface, Answer, momGameArray,} from './chatsArray';
import Timer from './Timer';
import MomGame from './MomGame';
import { Integration } from './Integration';
import { Balloons } from './Balloons';

interface State {
    isShowResult: boolean,
    questionNumber: number,
    currentMoney: number,
    currentChat: ChatInterface,
    currentTime: number,
    portrait: {
        schoolStar: number,
        generous: number,
        manOfWords: number,
    },
    momGameShowed: {
        0: boolean,
        1: boolean,
    },
    integrationsShowed: {
        0: boolean,
        1: boolean,
        2: boolean,
    },
    isBalloonGameShown: boolean,
}

export const startMoney = 12000;

export default class Main extends React.Component<{}, State> {
    constructor(props: any) {
        super(props)
        this.chats = chatsArray;
        this.state = {
            isShowResult: false,
            questionNumber: 0,
            currentMoney: startMoney,
            currentChat: this.chats[0],
            currentTime: 0,
            portrait: {
                schoolStar: 0,
                generous: 0,
                manOfWords: 0,
            },
            momGameShowed: {
                0: false,
                1: false,
            },
            integrationsShowed: {
                0: false,
                1: false,
                2: false,
            },
            isBalloonGameShown: false,
        };

        this.onNextChat = this.onNextChat.bind(this);
    }

    render() {
        let component;

        if (this.state.questionNumber === 0) {
            return <StartPage startQuestioning={() => this.startQuiz()} />;
        } else if (this.state.currentMoney < 1 && this.state.isShowResult) {
            return (
                <Result
                    restartQuiz={() => this.restartQuiz()}
                    moneyLeft={this.state.currentMoney}
                    isBunkrupt={true}
                    portrait={this.state.portrait}
                    positiveMistakeText={this.positiveQuestionsMistakesTexts[Math.floor(Math.random() * this.positiveQuestionsMistakesTexts.length)]}
                    negativeMistakeText={this.negativeQuestionsMistaeksTexts[Math.floor(Math.random() * this.negativeQuestionsMistaeksTexts.length)]}
                />
            )
        } else if (this.state.isShowResult) {
            return (
                <Result
                    restartQuiz={() => this.restartQuiz()}
                    moneyLeft={this.state.currentMoney}
                    portrait={this.state.portrait}
                    positiveMistakeText={this.positiveQuestionsMistakesTexts[Math.floor(Math.random() * this.positiveQuestionsMistakesTexts.length)]}
                    negativeMistakeText={this.negativeQuestionsMistaeksTexts[Math.floor(Math.random() * this.negativeQuestionsMistaeksTexts.length)]}
                />
            )
        }  else {
            component =
                <Chat
                    chat={this.state.currentChat}
                    onAnswerSelected={(answer: Answer) => this.onAnwerSelected(answer)}
                    onNextChat={this.onNextChat}
                    isTimeEnded={this.state.currentTime === 0}
                    clearInterval={() => this.clearInterval()}
                />;
        }

        if (this.state.questionNumber === 4 && !this.state.momGameShowed[0]) {
            return <MomGame currentChat={momGameArray[0]} onNext={() => this.onMomGameFinish()} />
        } else if (this.state.questionNumber === 10 && !this.state.momGameShowed[1]) {
            return <MomGame currentChat={momGameArray[1]} onNext={() => this.onMomGameFinish()} />
        }


        if (this.state.questionNumber === 5 && !this.state.integrationsShowed[0]) {
            return <Integration number={0} onNext={() => this.onIntegrationFinish()} />
        } else if (this.state.questionNumber === 11 && !this.state.integrationsShowed[1]) {
            return <Integration number={1} onNext={() => this.onIntegrationFinish()} />
        }

        if (this.state.questionNumber === 7 && !this.state.isBalloonGameShown) {
            return <Balloons onNext={() => this.onBalloonGameEnd()} />
        }

        return ( 
            <div className="game-area__aligner">
                <div className='game-area'>
                    <div className='game-area__header'>
                        <Progress questionNumber={this.state.questionNumber} />
                        <Timer currentTime={this.state.currentTime}/>
                        <MoneyCounter currentMoney={this.state.currentMoney} />
                    </div>
                    {component}
                </div>
            </div>
        )
    }

    private onBalloonGameEnd() {
        this.setState({
            isBalloonGameShown: true,
            currentTime: this.state.currentChat.time
        })
    }

    private onIntegrationFinish() {
        if (!this.state.integrationsShowed[0]) {
            this.setState({
                integrationsShowed: {
                    0: true,
                    1: false,
                    2: false,
                },
                currentTime: this.state.currentChat.time,
            })
        } else if (!this.state.integrationsShowed[1]) {
            this.setState({
                integrationsShowed: {
                    0: true,
                    1: true,
                    2: false,
                },
                currentTime: this.state.currentChat.time,
            })
        } else {
            this.setState({
                integrationsShowed: {
                    0: true,
                    1: true,
                    2: true,
                },
                currentTime: this.state.currentChat.time,
            })
        }
    }

    private onMomGameFinish() {
        if (!this.state.momGameShowed[0]) {
            this.setState({
                momGameShowed: {
                    0: true,
                    1: false,
                },
                currentTime: this.state.currentChat.time,
            })
        } else {
            this.setState({
                momGameShowed: {
                    0: true,
                    1: true,
                },
                currentTime: this.state.currentChat.time,
            })
        }
    }

    public onNextChat() {
        if (this.state.currentMoney < 1) {
            this.setState({
                isShowResult: true,
            })
        } else if (this.state.questionNumber < 12) {
            this.setState({ 
                currentChat: this.chats[this.state.questionNumber],
                questionNumber: this.state.questionNumber + 1,
                currentTime: this.chats[this.state.questionNumber].time,
            })
            this.setNewTimeInterval()
        } else {
            this.setState({
                isShowResult: true,
            })
        }
    }

    public clearInterval() {
        this.interval.stop()
    }

    public onAnwerSelected(answer: Answer) {
        let { portrait } = this.state
        this.interval.stop()
        if (this.state.questionNumber < 12) {
            this.setState({
                currentMoney: this.state.currentMoney - answer.price,
                portrait: {
                    schoolStar: portrait.schoolStar + (answer?.portrait?.schoolStar
                        ? answer.portrait.schoolStar : 0),
                    generous: portrait.generous + (answer?.portrait?.generous
                            ? answer.portrait.generous : 0),
                    manOfWords: portrait.manOfWords + (answer?.portrait?.manOfWords
                        ? answer.portrait.manOfWords : 0),
                }
            })

            if (answer.moral) {
                answer.moral.isPositive 
                    ? this.positiveQuestionsMistakesTexts.push(answer.moral.text)
                    : this.negativeQuestionsMistaeksTexts.push(answer.moral.text)
            }
        } else {
            this.setState({
                currentMoney: this.state.currentMoney - answer.price,
            })
        }
    }

    private startQuiz() {
        this.setState({ 
            questionNumber: 1,
            currentTime: this.chats[0].time
        })
    }

    private restartQuiz() {
        this.setState({
            isShowResult: false,
            questionNumber: 1,
            currentTime: this.chats[0].time,
            currentMoney: 12000,
            currentChat: this.chats[0],
            momGameShowed: {
                0: false,
                1: false,
            },
            integrationsShowed: {
                0: false,
                1: false,
                2: false,
            },
            portrait: {
                schoolStar: 0,
                generous: 0,
                manOfWords: 0,
            },
            isBalloonGameShown: false,
        })

        this.setNewTimeInterval()
        this.positiveQuestionsMistakesTexts = [];
        this.negativeQuestionsMistaeksTexts = [];
    }

    componentDidMount() {
        this.setNewTimeInterval()
    }

    private setNewTimeInterval() {
        this.interval = interval(
            () => this.setState(
                prevState => {
                    return {currentTime: prevState.currentTime > 0 ? prevState.currentTime - 1 : prevState.currentTime}
                })
        , 1000)
    }

    private interval: any
    private chats: Array<ChatInterface> = [];
    private positiveQuestionsMistakesTexts:Array<string> = [];
    private negativeQuestionsMistaeksTexts:Array<string> = [];
}
