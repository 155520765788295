import React from 'react';
import { Answer, ChatInterface } from './chatsArray';

interface Props {
    chat: ChatInterface,
    isTimeEnded: boolean,
    onAnswerSelected: (answer: Answer) => void,
    onNextChat: () => void,
    clearInterval: () => void,
}

interface State {
    messages: string[],
    scamIntegrationMessage: string,
    isShowAnswerButtons: boolean,
    checkboxesChecked: string[],
    isShowQuestionTypingDot: boolean,
    isShowAnswerTypingDot: boolean,
    questionMessagesQuantity: number,
    isShowNextButton: boolean,
    isDeleteAlignerClass: boolean,
}

export default class Chat extends React.Component<Props, State> {
    private sleep = (time: any) => new Promise(r => setTimeout(r, time))
    private interval: any = undefined;
    private messageWrapper: any
    private messageSound = new Audio('https://sbp-game.mel.fm/images/message.mp3');

    constructor(props: Props) {
        super(props);
        this.state = {
            messages: [this.props.chat.messages[0]],
            scamIntegrationMessage: '',
            isShowAnswerButtons: false,
            checkboxesChecked: [],
            isShowQuestionTypingDot: false,
            isShowAnswerTypingDot: false,
            questionMessagesQuantity: this.props.chat.messages.length,
            isShowNextButton: false,
            isDeleteAlignerClass: false,
        }
    }

    render() {
        const { chat } = this.props;


        return (
            <div className="chat">
                <div className="chat__contact">
                    <div className="chat__contact-icon">
                        <div>{chat.contactEmoji}</div>
                    </div>
                    <div>{chat.contactName}</div>
                </div>
                <div className="chat__messages-wrapper">
                    {this.state.messages.map(
                        (messageText, index) => {
                            let messageClass = index > this.state.questionMessagesQuantity - 1
                                ? 'chat__message chat__message_align-right'
                                : 'chat__message chat__message_align-left'
                            let messageTime = new Date();
                            let hours = messageTime.getHours()
                            let minutes = messageTime.getMinutes()

                            return (
                                <div className={messageClass} key={messageText}>
                                    <span className="chat__message-text">
                                        {messageText}
                                    </span>
                                    <div className="chat__message-time">
                                        {`${hours < 10 ? `0${hours}` : `${hours}`}:${minutes < 10 ? `0${minutes}` : `${minutes}`}`}
                                    </div>
                                </div>
                            )
                        }
                    )}
                    {this.state.scamIntegrationMessage &&
                        <div className="chat__message chat__scam-integration-message">
                            {`${this.state.scamIntegrationMessage}`}
                        </div>
                    }
                    {!this.state.scamIntegrationMessage &&
                        <>
                            <div className="chat__questionTypingDot-wrapper">
                                <span className={"chat__typingDot"} hidden={!this.state.isShowQuestionTypingDot} />
                            </div>
                            <div className="chat__answerTypingDot-wrapper" >
                                <span className={"chat__typingDot"} hidden={!this.state.isShowAnswerTypingDot} />
                            </div>
                        </>
                    }
                </div>
                <div className={`chat__answers-buttons-aligner ${chat.isCheckbox && !this.state.isDeleteAlignerClass ? 'chat__checkbox-anwers-buttons-aligner' : ''}`}>
                    {this.state.isShowAnswerButtons &&
                        (chat.isCheckbox
                            ?   // here we mean that answers[0] is positive [1] is negative
                            // for questions with checkboxes
                            // and checkboxes texts are in [0]
                            <div className="chat__answers-buttons-wrapper chat__checkbox-answers-wrapper">
                                <div className="chat__checkboxes-wrapper">
                                    {chat.answers[0].options && chat.answers[0].options.map(option =>
                                        <label htmlFor={option} className="chat__checkbox-wrapper" key={option}>
                                            <input
                                                type='checkbox'
                                                name={option}
                                                id={option}
                                                className='chat__checkbox'
                                                onChange={() => this.onCheckboxChange(option)}
                                            />
                                            <div className="chat__checkbox-checkmark"></div>
                                            {option}
                                        </label>
                                    )}
                                </div>
                                <button
                                    onClick={() => this.onCheckboxAnswerClicked()}
                                    className="chat__checkbox-answer-button"
                                >
                                    {this.state.checkboxesChecked.length < 1
                                        ? chat.answers[1].text
                                        : chat.answers[0].text
                                    }
                                </button>
                            </div>
                            :
                            <div className="chat__answers-buttons-wrapper">
                                {chat.answers.map(answer =>
                                    <button
                                        key={answer.text}
                                        className="chat__answer-button"
                                        onClick={() => this.onAnswerButtonClicked(answer)}
                                        style={{ backgroundColor: answer.color, }}
                                    >
                                        {answer.text}
                                    </button>
                                )}
                            </div>
                        )
                    }
                    <button
                        hidden={!this.state.isShowNextButton}
                        className="chat__answers-next-button"
                        onClick={() => this.onNextClick()}
                    >
                        <div className="button-inner-elements">
                            <div>Дальше</div>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95 99">
                                <g fill="none" fillRule="evenodd">
                                    <g strokeWidth="6.744">
                                        <g>
                                            <path stroke="#FFFFFF" d="M0 47.3L85.6 47.3" transform="translate(-158 -255) translate(158 258)" />
                                            <path stroke="#FFFFFF" d="M42.9 0L89.5 46.6" transform="translate(-158 -255) translate(158 258)" />
                                            <path stroke="#FFFFFF" d="M91.9 44.3L42.9 93.2" transform="translate(-158 -255) translate(158 258)" />
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                    </button>
                    <div className="safari-after-fixer" hidden={this.state.isShowAnswerButtons}></div>
                </div>
            </div>
        )
    }

    public async componentDidMount() {
        await this.sendMessagesWithTimeoutAndTypingDot(this.props.chat.messages, 'question')
        this.setState({
            isShowAnswerButtons: true,
        })
        this.messageWrapper = window.document.getElementsByClassName('chat__messages-wrapper')[0]
    }

    public async componentDidUpdate(prevProps: Props) {
        if (this.props.chat !== prevProps.chat) {
            this.setState({
                messages: [this.props.chat.messages[0]],
                questionMessagesQuantity: this.props.chat.messages.length,
                isShowNextButton: false,
                scamIntegrationMessage: '',
            })
            await this.sendMessagesWithTimeoutAndTypingDot(this.props.chat.messages, 'question')
            this.setState({
                isShowAnswerButtons: true,
            })
            if (this.messageWrapper) {
                this.messageWrapper.scrollTop = this.messageWrapper.scrollHeight;
            }
        } else if (this.props.isTimeEnded !== prevProps.isTimeEnded) {
            this.onTimeEnded()
        }
    }

    public componentWillUnmount() {
        clearInterval(this.interval);
    }

    private onTimeEnded() {
        if (!this.props.chat.isCheckbox) {
            this.onAnswerButtonClicked(this.props.chat.answers[1]);
        } else {
            this.onCheckboxAnswerClicked()
        }
    }

    private async onAnswerButtonClicked(answer: Answer) {
        if (!this.props.chat.isChatWithIntegration) {
            this.setState({
                isShowAnswerButtons: false,
            })
            this.props.onAnswerSelected(answer)
            await this.sendMessagesWithTimeoutAndTypingDot(answer.reactions, 'answer')
            this.setState({
                isShowNextButton: true,
            })
        } else {
            this.setState({
                isShowAnswerButtons: false,
                isShowNextButton: true,
                scamIntegrationMessage: answer.isPositive
                    ? this.scamIntegrationTexts[0]
                    : this.scamIntegrationTexts[1]
            })
            this.props.clearInterval()

            if (this.messageWrapper) {
                this.messageWrapper.scrollTop = this.messageWrapper.scrollHeight;
            }

            setTimeout(() => this.messageWrapper.scrollTop = this.messageWrapper.scrollHeight, 200)
        }
    }

    public onNextClick() {
        this.props.onNextChat()
    }

    private async onCheckboxAnswerClicked() {
        console.log('onCheckboxAnswer clicked')
        let { chat } = this.props;
        const answer = this.state.checkboxesChecked.length > 0
            ? chat.answers[0]
            : chat.answers[1]
        this.setState({
            isShowAnswerButtons: false,
            isDeleteAlignerClass: true,
        })
        this.props.onAnswerSelected(answer)
        await this.sendMessagesWithTimeoutAndTypingDot(answer.reactions, 'answer')
        this.setState({
            isShowNextButton: true,
        })
    }

    private onCheckboxChange(option: string) {
        let index = this.state.checkboxesChecked.indexOf(option);
        const checkboxesCheckedCopy = [...this.state.checkboxesChecked]
        if (index > -1) {
            checkboxesCheckedCopy.splice(index, 1);
            this.setState({
                checkboxesChecked: checkboxesCheckedCopy,
            })
        } else {
            checkboxesCheckedCopy.push(option)
            this.setState({
                checkboxesChecked: checkboxesCheckedCopy
            })
        }
    }

    private async sendMessagesWithTimeoutAndTypingDot(
        messages: string[],
        columnName: 'answer' | 'question'
    ) {
        for (let i = 0; i < messages.length; i++) {
            if (columnName === 'answer') {
                this.setState({
                    isShowAnswerTypingDot: true,
                })
                await this.sleep(1000)
                this.setState(prevState => ({
                    messages: [...prevState.messages, messages[i]],
                    isShowAnswerTypingDot: false,
                }))
                this.messageSound.play();
                if (this.messageWrapper) {
                    this.messageWrapper.scrollTop = this.messageWrapper.scrollHeight;
                }
            } else {
                if (i === 0) {
                    // do nothing because first item already shown
                } else {
                    this.setState({
                        isShowQuestionTypingDot: true,
                    })
                    await this.sleep(1000)
                    this.setState(prevState => ({
                        messages: [...prevState.messages, messages[i]],
                        isShowQuestionTypingDot: false,
                    }))
                    if (this.messageWrapper) {
                        this.messageWrapper.scrollTop = this.messageWrapper.scrollHeight;
                    }
                }
            }
        }
    }

    private scamIntegrationTexts: string[] = [
        'О нет! Вам написали мошенники. Хорошая новость: с вашим ребёнком всё в порядке (в таких ситуациях лучше сразу же позвонить на его номер и спросить, что случилось). Вторая хорошая новость: кнопку заклинило, и перевод не прошёл. Фууух!',
        'Да-да, это были мошенники, но вас не проведёшь — деньги целы, так держать!'
    ]
}