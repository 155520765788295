import React from 'react';
import { FloatingRubleIcons } from './FloatingRubleIcons';
import { TopIcons } from './TopIcons';

interface Props {
    startQuestioning: () => void;
}

export default class Main extends React.Component<Props> {

    render() {
        return (
            <div className="start-page__layout">
                <div className='start-page'>
                    <TopIcons isStartPage={true}/>
                    <div className="start-page__title">
                            {`Знаете ли вы, на что \n
                        стоит тратить \n
                        деньги, а на что нет?`}
                        <FloatingRubleIcons />
                    </div>
                    <div className="start-page__description">
                        А если у вас в кармане 12 000 рублей и 12 секунд на каждое решение? Самое время проверить. Система быстрых платежей предлагает сыграть в игру: скинуться на всё-всё-всё и не разориться.
                    </div>
                    <button
                        onClick={() => {this.props.startQuestioning(); gtag('event', 'start-button-clicked')}}
                        className='start-page__start-button'
                    >
                        Начать игру
                </button>
                
                </div>
            </div>
        )
    }
}