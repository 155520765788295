import React from 'react';

interface Props {
    questionNumber: number
}

export default class Progress extends React.Component<Props> {

    render() {
        return (
            <div className='progress'>
                <div className='progress__text'>
                    {`${this.props.questionNumber} / 12`}
                </div>
                <div className="progress__icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95 99">
                        <g fill="none" fillRule="evenodd">
                            <g strokeWidth="6.744">
                                <g>
                                    <path stroke="#E50050" d="M0 47.3L85.6 47.3" transform="translate(-158 -255) translate(158 258)" />
                                    <path stroke="#5B57A2" d="M42.9 0L89.5 46.6" transform="translate(-158 -255) translate(158 258)" />
                                    <path stroke="#954B97" d="M91.9 44.3L42.9 93.2" transform="translate(-158 -255) translate(158 258)" />
                                </g>
                            </g>
                        </g>
                    </svg>

                </div>
            </div>
        )
    }
}