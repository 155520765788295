import React from 'react';

interface Props {
    currentTime: number,
}

export default class Timer extends React.Component<Props> {
    render() {
        return (
            <div className="timer">
                <div className="timer__counter">{this.props.currentTime > -1 ? this.props.currentTime : 0}</div>
                <div className="timer__icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 88">
                        <g fill="none" fillRule="evenodd">
                            <g strokeWidth="6.744">
                                <g>
                                    <path stroke="#5B57A2" d="M34.1 0L34.1 16.8M21.6 2.7L46.5 2.7" transform="translate(-45 -257) translate(49 258)" />
                                    <path stroke="#954B97" d="M34.1 82.9C15.3 82.9 0 67.6 0 48.8s15.3-34.1 34.1-34.1S68.2 30 68.2 48.8 52.9 82.9 34.1 82.9" transform="translate(-45 -257) translate(49 258)" />
                                    <path stroke="#E50050" d="M50.4 33.7L32.7 49.6" transform="translate(-45 -257) translate(49 258)" />
                                </g>
                            </g>
                        </g>
                    </svg>
                </div>
                <div className="timer__circles">
                    <div className="timer__circle-1"></div>
                    <div className="timer__circle-2"></div>
                    <div className="timer__circle-3"></div>
                </div>
            </div>
        )
    }
}
