import React from 'react';

interface Props {
    currentMoney: number
}

export default class MoneyCounter extends React.Component<Props> {

    render() {
        return (
            <div className='money'>
                <div className='money__text'>
                    {this.props.currentMoney}
                </div>
                <div className="money__icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 71 88">
                        <g fill="none" fillRule="evenodd">
                            <g strokeWidth="6.744">
                                <g>
                                    <path stroke="#EE7225" d="M15.8 3.5h29.7c11.9 0 21.6 9.7 21.6 21.6 0 11.9-9.7 21.6-21.6 21.6H15.8" transform="translate(-290 -261) translate(290 261)" />
                                    <path stroke="#FBBB20" d="M15.8 87.9L15.8 0" transform="translate(-290 -261) translate(290 261)" />
                                    <path stroke="#E50050" d="M0 67.1L47.1 67.1" transform="translate(-290 -261) translate(290 261)" />
                                </g>
                            </g>
                        </g>
                    </svg>
                </div>
            </div>
        )
    }
}

