import React from 'react';
import { FloatingRubleIcons } from './FloatingRubleIcons';
import { startMoney } from './Main';
import MoneyCounter from './MoneyCounter';
import { TopIcons } from './TopIcons';

interface Props {
    restartQuiz: () => void,
    moneyLeft: number,
    portrait: {
        schoolStar: number,
        generous: number,
        manOfWords: number,
    },
    isBunkrupt?: boolean,
    positiveMistakeText?: string,
    negativeMistakeText?: string,
}

interface State {
    isShowSharing: boolean,
}

export default class Result extends React.Component <Props, State> {

    constructor(props: Props) {
        super(props)
        this.state = {
            isShowSharing: false,
        }
    }

    componentDidMount(): void {
        if (this.props.moneyLeft < 1) {
            gtag('event', 'money-ended');
        }

        gtag('event', 'result-showed');
    }

    render() {
        let currentPortret: ResultObject;
        const { portrait, moneyLeft } = this.props;

        if (this.props.isBunkrupt) {
            currentPortret = results.bigWallet
        } else if (moneyLeft === startMoney) {
            currentPortret = results.thrifty
        } else {
            let maxPointsPortraitName = 'schoolStar';
            let maxPoints = 0;

            Object.keys(portrait).forEach(key => {
                if ((portrait as any)[key] > maxPoints) {
                    maxPoints = (portrait as any)[key]
                    maxPointsPortraitName = key;
                }
            })

            currentPortret = (results as any)[maxPointsPortraitName];
        }

        let sharingUrl = `https://sbp-game.mel.fm/${currentPortret.sharingQuery}`;
        let sharingText = `Знаете ли вы, на что тратить деньги, а на что нет?`;

        return (
                <div className="result-layout">
                    <div className='result'>
                        <TopIcons />
                        <MoneyCounter currentMoney={this.props.moneyLeft}/>

                        <div className='result__title'>
                            {currentPortret?.title}
                            <FloatingRubleIcons />
                        </div>

                        <div className='result__description result__text'>
                            {currentPortret?.description}
                        </div>

                        {this.props.positiveMistakeText &&
                            <div className="result__positive-mistake result__text">
                                {`😀 ${this.props.positiveMistakeText}`}
                            </div>
                        }

                        {this.props.negativeMistakeText &&
                            <div className="result__negative-mistake result__text">
                                {`☹️ ${this.props.negativeMistakeText}`}
                            </div>
                        }
                        <div className="result__share-button-wrapper">
                            {this.state.isShowSharing
                                ? 
                                    <div 
                                        className="result__opened-share-button"
                                        onClick={() => this.setState({isShowSharing: false})}
                                    >
                                        <a 
                                            className="result__share-option"
                                            href={`https://vk.com/share.php?url=${sharingUrl}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            onClick={() => gtag('event', 'vk-sharing-button-clicked')}
                                        >Вконтакте</a>

                                        <a 
                                            className="result__share-option"
                                            href={`https://www.facebook.com/sharer/sharer.php?u=${sharingUrl}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            onClick={() => gtag('event', 'facebook-sharing-button-clicked')}
                                        >Facebook</a>

                                        <a 
                                            className="result__share-option"
                                            href={`https://t.me/share/url?text=${sharingText}&url=${sharingUrl}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            onClick={() => gtag('event', 'telegram-sharing-button-clicked')}
                                        >Telegram</a>
                                        
                                        <a 
                                            className="result__share-option"
                                            href={`whatsapp://send?text=${sharingText} ${sharingUrl}`} data-action="share/whatsapp/share"
                                            onClick={() => gtag('event', 'whatsapp-sharing-button-clicked')}
                                        >Whatsapp</a>
                                        <div className="result__close-button" onClick={() => this.setState({isShowSharing: false})}>
                                            <svg id="Capa_1" enable-background="new 0 0 512.001 512.001" viewBox="0 0 512.001 512.001" xmlns="http://www.w3.org/2000/svg"><g><path d="m512.001 84.853-84.853-84.853-171.147 171.147-171.148-171.147-84.853 84.853 171.148 171.147-171.148 171.148 84.853 84.853 171.148-171.147 171.147 171.147 84.853-84.853-171.148-171.148z"/></g></svg>
                                        </div>
                                    </div>
                                : 
                                    <button 
                                        className="result__share-button"
                                        onClick={() => this.setState({isShowSharing: true})}
                                    >
                                        <span>Рассказать<br/>друзьям</span>
                                    </button>
                            }
                        </div>

                        <button onClick={() => {this.props.restartQuiz(); gtag('event', 'play-again-button-clicked')}} className="result__restart-button">
                            Играть ещё раз   
                        </button>
                        
                    </div>
                </div>
        )
    }
}

interface ResultObject {
    title: string,
    description: string,
    sharingQuery: string,
}

const results: {bigWallet: ResultObject, thrifty: ResultObject, schoolStar: ResultObject, generous: ResultObject, manOfWords: ResultObject} = {
    bigWallet: {
        title: 'Человек и кошелёк',
        description: 'Вы купили всё, вообще всё — тратите деньги со скоростью света. И они у вас закончились.',
        sharingQuery: 'wealthy',
    },
    thrifty: {
        title: 'Магистр экономии',
        description: 'Единственный родитель на свете, который не потратил на ребёнка ни рубля. Погодите, а дети-то у вас есть вообще?',
        sharingQuery: 'thrifty',
    },
    schoolStar: {
        title: 'Звезда родительского комитета',
        description: 'Скинуться на экскурсию? Собрать деньги на цветы? Вы, как пионер, всегда готовы.',
        sharingQuery: 'schoolStar',
    },
    generous: {
        title: 'Щедрая душа',
        description: 'Все дети и животные радостно замирают, если вы проходите мимо: вдруг вы и им что-нибудь купите?',
        sharingQuery: 'generous',
    },
    manOfWords: {
        title: 'Человек слова',
        description: '«Нет слова „не хочу“» и «Надо значит надо» — ваши жизненные принципы. Сказано купить гобелен или древнеегипетские счёты — сделано.',
        sharingQuery: 'manOfWords',
    }
}